import { useContext } from 'react';
import { useQuery } from "@apollo/client";

import {
  Form,
  Row,
  Col,
  Table,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

import {
  Link
} from 'react-router-dom';

import moment from 'moment';
// TODO: import task editor?
//import UnitEditor from "../components/UnitEditor";

import {
  TASKS_QUERY
} from "../typeDefs";

import { TasksSearchContext } from "../contexts/TasksSearchContext";
import TaskStatusBadge from "../components/TaskStatusBadge";
import PriorityBadge from "../components/PriorityBadge";

function formatUpdatedAt(updatedAt) {
  if (!updatedAt) return '-';
  return moment(Number(updatedAt)).format("MM.DD HH:mm");
}

function TasksPage() {
  const { loading, error, data } = useQuery(TASKS_QUERY, {
    pollInterval: 10000
  });

  const { searchValueState, assignedState, statusState, sortingValueState } = useContext(TasksSearchContext);

  const [searchValue, setSearchValue] = searchValueState;
  const [assigned, setAssigned] = assignedState;
  const [status, setStatus] = statusState;
  const [sortingValue, setSortingValue] = sortingValueState;

  if (loading) return <p>Betöltés...</p>
  if (error) return <p>Hiba a feladatok betöltése közben.</p>
  if (!data.tasks) return <p>Üres..! TODO</p>

  let filteredTable = data.tasks || [];

  if (searchValue) {
    const input = searchValue.toLowerCase();
    filteredTable = filteredTable.filter(task => {
      return task.unitName?.toLowerCase()?.includes(input)
        || task.content?.toLowerCase()?.includes(input)
    });
  }

  if (assigned !== "") {
    filteredTable = filteredTable.filter(task => {
      return task.assignedUsername === assigned;
    });
  }

  if (status !== "") {
    if (status === "NOT_DONE") {
      filteredTable = filteredTable.filter(task => {
        return task.status !== "DONE";
      });
    }
    else {
      filteredTable = filteredTable.filter(task => {
        return task.status === status;
      });
    }
  }

  function priorityValue(task) {
    switch (task.priority) {
      case "LOW":
        return 0;
      case "NORMAL":
        return 1;
      case "HIGH":
        return 2;
      default:
        return 3;
    }
  }

  let sortingFn;
  switch (sortingValue) {
    case "priorityFirst":
      sortingFn = (task1, task2) => priorityValue(task2) - priorityValue(task1);
      break;
    case "modifiedDesc":
      sortingFn = (task1, task2) => task2.updatedAt - task1.updatedAt;
      break;
    default:
      sortingFn = (task1, task2) => task2.updatedAt - task1.updatedAt;
  }
  filteredTable = [...filteredTable].sort(sortingFn);

  return (
    <>
      <div className="units-search">
        <Form>
          <Row>
            <Col>
              <InputGroup>
                <InputGroup.Text>Kereső</InputGroup.Text>
                <FormControl
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Feladat leírás, egység neve..."
                />
              </InputGroup>
            </Col>

            <Col md={3}>
              <InputGroup>
                <InputGroup.Text>Hozzárendelt</InputGroup.Text>
                <Form.Select value={assigned}
                      onChange={(e) => setAssigned(e.target.value)}>
                  <option value="">Mindegy</option>
                  <option value="nesztor">nesztor</option>
                  <option value="csenki.daniel">csenki.daniel</option>
                  <option value="varga.kinga">varga.kinga</option>
                  <option value="halasz.rita">halasz.rita</option>
                </Form.Select>
              </InputGroup>
            </Col>

            <Col md={3}>
              <InputGroup>
                <InputGroup.Text>Státusz</InputGroup.Text>
                <Form.Select value={status}
                      onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Mindegy</option>
                  <option value="NOT_DONE">Nincs kész</option>
                  <option value="NEW">Új feladat</option>
                  <option value="IN_PROGRESS">Folyamatban</option>
                  <option value="BLOCKED">Várunk...</option>
                  <option value="REVIEW">Ellenőrzés</option>
                  <option value="DONE">Kész</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>

          <Row style={{ 'marginTop': '6px' }}>
            <Col md={{ span: 6, offset: 6 }}>
              <InputGroup>
                <InputGroup.Text>Rendezés</InputGroup.Text>
                <Form.Select value={sortingValue}
                    onChange={(e) => setSortingValue(e.target.value)}>
                  <option value="priorityFirst">Prioritás szerint</option>
                  <option value="modifiedDesc">Módosítva csökkenő</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>

      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th colSpan={6}>
              {filteredTable.length !== data.tasks.length
                ? <span>Találatok: {filteredTable.length} / {data.tasks.length}</span>
                : <span>Összes feladat: {data.tasks.length}</span>
              }
            </th>
          </tr>
          <tr>
            <th>Feladatleírás</th>
            <th>Egység</th>
            <th className="text-center">Státusz</th>
            <th className="text-center">Prioritás</th>
            <th className="text-center">Hozzárendelve</th>
            <th className="text-center">Módosítva</th>
          </tr>
        </thead>

        <tbody>
          {filteredTable.map(task => (
            <tr key={task._id}>
              <td className="align-middle">{task.content}</td>
              <td className="align-middle">
                <Link to={'/' + task.unitId}>
                  {task.unitName}
                </Link>
              </td>
              <td className="text-center align-middle"><TaskStatusBadge status={task.status} /></td>
              <td className="text-center align-middle"><PriorityBadge priority={task.priority} /></td>
              <td className="text-center align-middle">{task.assignedUsername}</td>
              <td className="text-center align-middle text-nowrap">{formatUpdatedAt(task.updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

  );
}

export default TasksPage;