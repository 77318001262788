import { useState } from 'react';
import moment from 'moment';
import { Formik, Field, Form } from 'formik';
import { useQuery, useMutation } from "@apollo/client";
import toast from 'react-hot-toast';

import {
  Button,
  Row,
  Col,
  Table,
  Modal,
} from 'react-bootstrap';

import TaskStatusBadge from "../components/TaskStatusBadge";
import PriorityBadge from './PriorityBadge';

import {
  TASKS_QUERY,
  TASKS_FOR_UNIT_QUERY,
  ADD_TASK_MUTATION,
  UPDATE_TASK_MUTATION,
} from "../typeDefs";

export default function TaskTableForUnit({ unitId, unitName }) {
  const { loading, error, data } = useQuery(TASKS_FOR_UNIT_QUERY, {
    variables: { unitId: parseInt(unitId) }
  });

  const [addTask] = useMutation(ADD_TASK_MUTATION, {
    onCompleted: () => { toast.success('Sikeres mentés!') },
    onError: () => { toast.error('Hiba a mentés során!') },
    refetchQueries: [{
      query: TASKS_FOR_UNIT_QUERY,
      variables: { unitId: parseInt(unitId) }
    }, {
      query: TASKS_QUERY
    }]
  });

  const [updateTask] = useMutation(UPDATE_TASK_MUTATION, {
    onCompleted: () => { toast.success('Sikeres mentés!') },
    onError: () => { toast.error('Hiba a mentés során!') },
    refetchQueries: [{
      query: TASKS_FOR_UNIT_QUERY,
      variables: { unitId: parseInt(unitId) }
    }, {
      query: TASKS_QUERY
    }]
  });

  const [taskToEdit, setTaskToEdit] = useState(null);

  function formatUpdatedAt(updatedAt) {
    if (!updatedAt) return '-';
    return moment(Number(updatedAt)).format("YYYY.MM.DD HH:mm");
  }

  function editTask(task) {
    setTaskToEdit(task);
  }

  function closeEditorModal() {
    setTaskToEdit(null);
  }

  function editNewTask() {
    setTaskToEdit({
      assignedUsername: "",
      status: "NEW",
      priority: "NORMAL",
      content: "",
    });
  }

  function onTaskEditorSubmit(values, { setSubmitting }) {
    setSubmitting(true);

    if (!values._id) {
      saveNewTask(values);
    }
    else {
      saveExistingTask(values);
    }
  }

  function saveNewTask(values) {
    const saving = addTask({ variables: {
      task: {
        unitId: parseInt(values.unitId),
        unitName: values.unitName,
        content: values.content,
        assignedUsername: values.assignedUsername,
        status: values.status,
        priority: values.priority,
      }
    } });

    saving.then(() => {
      setTaskToEdit(null);
    });
  }

  function saveExistingTask(values) {
    const saving = updateTask({ variables: {
      taskId: values._id,
      task: {
        unitId: parseInt(values.unitId),
        unitName: values.unitName,
        content: values.content,
        assignedUsername: values.assignedUsername,
        status: values.status,
        priority: values.priority,
      }
    } });

    saving.then(() => {
      setTaskToEdit(null);
    });
  }

  if (loading) return <p>Betöltés...</p>
  if (error) return <p>Hiba a feladatok betöltése közben.</p>
  if (!data.tasksFor) return <p>Nincsenek feladatok!</p>

  return (
    <>

        <Modal show={taskToEdit !== null} onHide={closeEditorModal} centered size="lg">

          <Formik initialValues={{ ...taskToEdit, unitId: unitId, unitName: unitName }}
                  onSubmit={onTaskEditorSubmit}>
          { ({ isSubmitting }) => (
            <Form>
            <Modal.Body>
                  <Field name="unitName" className="form-control" disabled />

                  <Row>
                    <Col>
                      <label htmlFor="assignedUsername" className="form-label mt-2">Hozzárendelve</label>
                      <Field as="select" name="assignedUsername" className="form-select">
                        <option value="">-</option>
                        <option value="nesztor">nesztor</option>
                        <option value="csenki.daniel">csenki.daniel</option>
                        <option value="varga.kinga">varga.kinga</option>
                        <option value="halasz.rita">halasz.rita</option>
                      </Field>
                    </Col>

                    <Col>
                      <label htmlFor="status" className="form-label mt-2">Státusz</label>
                      <Field as="select" name="status" className="form-select">
                        <option value="NEW">Új feladat</option>
                        <option value="IN_PROGRESS">Folyamatban</option>
                        <option value="BLOCKED">Várunk...</option>
                        <option value="REVIEW">Ellenőrzés</option>
                        <option value="DONE">Kész</option>
                      </Field>
                    </Col>

                    <Col>
                      <label htmlFor="priority" className="form-label mt-2">Prioritás</label>
                      <Field as="select" name="priority" className="form-select">
                        <option value="LOW">Ráérős</option>
                        <option value="NORMAL">Normál</option>
                        <option value="HIGH">SOS!</option>
                      </Field>
                    </Col>
                  </Row>
                  

                  

                  

                  <label htmlFor="content" className="form-label mt-2">Feladatleírás</label>
                  <Field as="textarea" className="form-control" name="content" rows={3}></Field>
            </Modal.Body>

            <Modal.Footer style={{"textAlign": "left"}}>
              <Button variant="outline-secondary" onClick={closeEditorModal}>
                Mégse
              </Button>

              { taskToEdit?._id ?
                <Button variant="primary" disabled={isSubmitting} type="submit"
                    style={{"width": "180px"}}>
                  Mentés
                </Button>
                :
                <Button variant="primary" disabled={isSubmitting} type="submit"
                    style={{"width": "180px"}}>
                  Feladat létrehozása
                </Button>
              }
                
            </Modal.Footer>
            </Form>
        )}
        </Formik>
      </Modal>


    <div className="card">
      <div className="card-header">
        Feladatok az egységgel: {unitName}
      </div>
      <div className="card-body">

        <button className="btn btn-outline-primary" type="button"
            style={{ "width": "220px", "margin": "-8px 0 8px 0", "padding": "5px" }}
            onClick={editNewTask}>
            Új feladat hozzáadása
        </button>

        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Feladatleírás</th>
              <th className="text-center">Státusz</th>
              <th className="text-center">Prioritás</th>
              <th className="text-center">Hozzárendelve</th>
              <th className="text-center">Módosítva</th>
            </tr>
          </thead>

          <tbody>
            {data.tasksFor.map(task => (
              <tr key={task._id}>
                <td className="text-center align-middle">
                  <Button variant="outline-secondary" onClick={() => editTask(task)}
                          className="m-0 p-1 px-2">
                    <i className="bi bi-pencil"></i>
                  </Button>
                </td>
                <td className="align-middle">{task.content}</td>
                <td className="text-center align-middle"><TaskStatusBadge status={task.status} /></td>
                <td className="text-center align-middle"><PriorityBadge priority={task.priority} /></td>
                <td className="text-center align-middle">{task.assignedUsername}</td>
                <td className="text-center align-middle">{formatUpdatedAt(task.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}