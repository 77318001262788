export function fuzzyFilterForUnit(e, input) {
  return e.egyseg_nev.toLowerCase()?.includes(input)
    || e.egyseg_tipus?.toLowerCase()?.includes(input)
    || e.telepules_nev?.toLowerCase()?.includes(input)
    || e.felir_szam?.toLowerCase()?.includes(input)
    || e.tanusitvany_szam?.toLowerCase()?.includes(input)
    || e.egyseg_cim?.toLowerCase()?.includes(input)
    || e.uzemelteto_ceg?.nev?.toLowerCase()?.includes(input)
    || e.uzemelteto_ceg?.cim?.toLowerCase()?.includes(input)
    || e.kapcsolattarto?.nev?.toLowerCase()?.includes(input)
    || e.kapcsolattarto?.email?.toLowerCase()?.includes(input)
    || e.kapcsolattarto?.telefonszam?.toLowerCase()?.includes(input)
    || e.kapcsolattarto2?.nev?.toLowerCase()?.includes(input)
    || e.kapcsolattarto2?.email?.toLowerCase()?.includes(input)
    || e.kapcsolattarto2?.telefonszam?.toLowerCase()?.includes(input);
}